import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Toaster, useToasterStore, toast } from 'react-hot-toast';
import useAppState from 'context/useAppState';
import Page from 'routes/RouteList';
import Loading from 'components/Loading';
import useApp from './useApp';
import * as Sentry from '@sentry/react';

function App() {
  const { pathname } = useLocation();
  const { toasts } = useToasterStore();
  const { isOpen } = useAppState((state) => state.loading);
  const TOAST_LIMIT = 3;
  const { checkCredential, scrollToTop, getInitialData, loading } = useApp();

  useEffect(() => {
    toasts
      .filter((t) => t.visible)
      .filter((item, i) => i >= TOAST_LIMIT)
      .forEach((t) => toast.dismiss(t.id));
    getInitialData();
  }, [getInitialData]);

  useEffect(() => {
    checkCredential();
    scrollToTop();
  }, [pathname, checkCredential, scrollToTop]);

  if (loading)
    return <div className="fixed inset-0 h-screen w-full bg-black/50">loading credential...</div>;

  return (
    <>
      <Page />
      <Toaster />
      {!isOpen ? <></> : <Loading />}
    </>
  );
}
let Pages = App;
if (window.REACT_APP_SENTRY_ENV !== '') {
  Pages = Sentry.withProfiler(App);
}

export default Pages;
