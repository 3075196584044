import { MSL_API_URL } from 'environment';

const BRANCH_BASE_URL = `${MSL_API_URL}/admin/pengaturan/branch-location`;

export async function getBranchAPI(token, query) {
  const myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'GET', headers: myHeaders, redirect: 'follow' };

  return fetch(`${BRANCH_BASE_URL}${query}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function addBranchAPI(
  token,
  city_name,
  branch_code,
  branch_id,
  detail_address,
  maps_url,
  is_active,
  adira_id,
) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify({
    city_name,
    branch_code,
    branch_id,
    detail_address,
    maps_url,
    is_active,
    adira_id,
  });

  const requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${BRANCH_BASE_URL}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function updateBranchAPI(
  token,
  id,
  city_name,
  branch_code,
  branch_id,
  detail_address,
  maps_url,
  is_active,
  adira_id,
) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify({
    city_name,
    branch_code,
    branch_id,
    detail_address,
    maps_url,
    is_active,
    adira_id,
  });

  const requestOptions = { method: 'PUT', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${BRANCH_BASE_URL}/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function updateBranchStatusAPI(token, id, is_active) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const raw = JSON.stringify({
    is_active: !is_active,
  });

  const requestOptions = { method: 'PUT', headers: myHeaders, body: raw, redirect: 'follow' };

  return fetch(`${BRANCH_BASE_URL}/${id}/active-inactive`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}

export async function removeBranchAPI(token, id) {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const requestOptions = { method: 'DELETE', headers: myHeaders, redirect: 'follow' };

  return fetch(`${BRANCH_BASE_URL}/${id}`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => error);
}
